var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Browse',{attrs:{"columns":[
    'name',
    'dob',
    'sex',
    'ssn',
    'tax_code',
    'curp',
    'address' ],"formats":{
    dob: 'Date',
    sex: 'Select*',
  },"itemsPerPage":"10","searchOnStart":true,"table":"employees"}})}
var staticRenderFns = []

export { render, staticRenderFns }