<template>
  <Browse
    :columns="[
      'name',
      'dob',
      'sex',
      'ssn',
      'tax_code',
      'curp',
      'address',
    ]"
    :formats="{
      dob: 'Date',
      sex: 'Select*',
    }"
    itemsPerPage="10"
    :searchOnStart="true"
    table="employees"
  />
</template>

<script>
import Browse from "@/components/Browse.vue";

export default {
  components: {
    Browse,
  },
};
</script>
